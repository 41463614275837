import React from 'react';
import { useNavigate } from 'react-router-dom';
import Car from '../../assets/images/car.png';
import Truck from '../../assets/images/truck.png';
import Profile from '../../common/profile';
import { Back } from '../../common/back';

const SelectVehicle = () => {
  const navigate = useNavigate();
  const handleCar = () => {
    navigate('/wheeler-billing');
  };
  const handleTruck = () => {
    navigate('/heavy-vehicle-billing');
  };

  return (
    <div className="p-4">
      <div className="d-flex align-items-center justify-content-end mt-15">
        <Profile />
      </div>

      <div className="mt-5 select-vehicle-container p-4">
        <div className="pt-3">
          <div className="d-flex align-items-center">
            <Back />
            <p className="ps-2 fs-22 fw-600">New Service</p>
          </div>

          <p className="fs-22 fw-normal mt-10">Select the type of vehicle</p>
        </div>
        <div className="pt-3">
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={handleCar}
              className="btn-no-style"
              aria-label="Select Car"
            >
              <img src={Car} alt="Car icon" />
            </button>
            <button
              onClick={handleTruck}
              className="btn-no-style"
              aria-label="Select Truck"
            >
              <img src={Truck} alt="Truck icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectVehicle;
