import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { numberFormatter } from './numberFormatter';

const ViewAll = ({
  pageIndex = 0,
  pageSize = 10,
  totalItems,
  to = '#',
  viewAllText = 'View all',
  customClassName = ''
}) => {
  const start = pageIndex * pageSize + 1;
  const end = Math.min((pageIndex + 1) * pageSize, totalItems);

  return (
    <p className={`grayish-blue fs-14 fw-500 ${customClassName}`}>
      {totalItems > 0
        ? `Showing ${start} to ${end} of ${numberFormatter(totalItems)} entries`
        : 'No entries available'}
      {totalItems > 0 && (
        <Link
          to={to}
          className="ps-1 pointer ms-2"
          style={{ color: '#127BFF', textDecoration: 'none' }}
        >
          {viewAllText}
        </Link>
      )}
    </p>
  );
};

ViewAll.propTypes = {
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  totalItems: PropTypes.number.isRequired,
  to: PropTypes.string,
  viewAllText: PropTypes.string,
  customClassName: PropTypes.string
};

export default ViewAll;
