import { Appconfig } from '../config/appConfig';
import axiosInstance from '../tokenInterceptor/axiosInstance';

export class ReturnTrackerRequest {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'return-tracker';
  }

  fetchReturnCountsForBranch(branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/count`, {
      params: { branchName, organisationName }
    });
  }

  searchReturnsBySerialNumber(
    organisationName,
    branchName,
    serialNumber,
    page,
    size
  ) {
    return axiosInstance.get(`${this.Api}/find-by`, {
      params: { organisationName, branchName, serialNumber, page, size }
    });
  }

  sortReturnRequests(order, organisationName, branchName, page, size) {
    return axiosInstance.get(`${this.Api}/return-requests`, {
      params: { order, organisationName, branchName, page, size }
    });
  }

  fetchReturnTrackerDetails(serialNumber, organisationName, branchName) {
    return axiosInstance.get(`${this.Api}`, {
      params: { serialNumber, organisationName, branchName }
    });
  }

  fetchReturnCountsForCustomer(organisationName, branchName, customerName) {
    return axiosInstance.get(`${this.Api}/customer/status-count`, {
      params: { organisationName, branchName, customerName }
    });
  }

  searchReturnsByCustomerName(
    organisationName,
    branchName,
    customerName,
    serialNumber,
    page,
    size
  ) {
    return axiosInstance.get(`${this.Api}/search`, {
      params: {
        organisationName,
        branchName,
        customerName,
        serialNumber,
        page,
        size
      }
    });
  }

  sortedReturnRequestsForCustomer(
    organisationName,
    branchName,
    order,
    customerName,
    page,
    size
  ) {
    return axiosInstance.get(`${this.Api}/request`, {
      params: { organisationName, branchName, order, customerName, page, size }
    });
  }
  tyreModels(tyreManufacturer) {
    return axiosInstance.get(`${this.Api}/tyre-models`, {
      params: { tyreManufacturer }
    });
  }
}

export default ReturnTrackerRequest;
