import React, { useRef, useEffect, useState, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../core/loader/loader';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import DataTransferService from '../../core/service/dataTransferService';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import ServiceRequest from '../../core/service/serviceRequest';
import InvoiceService from '../../core/service/invoiceService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState('');
  const paymentTypeRef = useRef(null);
  const [creditStatus, setCreditStatus] = useState('unsettled');
  const [error, setError] = useState(null);
  const invoiceService = new InvoiceService();
  const { invoiceSerialNumber } = useParams();

  const fetchData = useCallback(() => {
    const serviceRequest = new ServiceRequest();
    const dataTransferService = new DataTransferService();
    serviceRequest
      .fetchInvoice(
        invoiceSerialNumber,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setInvoiceData(response.data.responseBody);
          if (invoiceData?.creditStatus) {
            setCreditStatus(invoiceData.creditStatus.toLowerCase());
          }
        }
      })
      .catch(error => {
        setLoading(false);
        if (error.response?.data?.responseMessage) {
          setError(error.response.data.responseMessage);
        } else {
          handleErrorResponse('fetchData', error.response);
        }
      });
  }, [invoiceSerialNumber, invoiceData.creditStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!invoiceData) {
    return <p>No invoice data found.</p>;
  }

  const isoDateString = new Date(invoiceData.createdOn).toLocaleDateString(
    'en-GB'
  );
  const upcominAlertDate = invoiceData.upcomingAlert
    ? invoiceData.upcomingAlert.split('-').reverse().join('/')
    : null;

  const handleSaveAndPrint = () => {
    document.title = invoiceData.invoiceSerialNumber;
    window.print();
  };

  const handleChangeCreditStatus = () => {
    setCreditStatus(prevStatus =>
      prevStatus === 'unsettled' ? 'settled' : 'unsettled'
    );
  };

  const handlePaymentTypeChange = async () => {
    const dataTransferService = new DataTransferService();
    const newPaymentType = paymentTypeRef.current.value;
    setPaymentType(newPaymentType);

    try {
      await invoiceService.updatePaymentType(
        invoiceSerialNumber,
        newPaymentType.toUpperCase(),
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      );
    } catch (error) {
      console.error('Error updating payment type:', error);
      handleErrorResponse('invoice', error.response);
    }
  };

  const updateCreditStatus = async () => {
    const dataTransferService = new DataTransferService();
    setCreditStatus(creditStatus);
    try {
      await invoiceService.updateCreditstatus(
        creditStatus.toUpperCase(),
        invoiceSerialNumber,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      );
      toast.success('Credit status updated successfully.', {
        theme: 'colored'
      });
    } catch (error) {
      console.error('Error updating credit status:', error);
      handleErrorResponse('invoice', error.response);
    }
  };
  const dataTransferService = new DataTransferService();
  const currentStatus = creditStatus || invoiceData.creditStatus?.toLowerCase();
  const isSettled = currentStatus === 'settled';

  if (error) {
    return (
      <div className="vh-100 position-relative d-flex justify-content-center align-items-center">
        <div className="position-absolute top-0 end-0 mt-3 p-4">
          <Profile />
        </div>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  const serviceRows = [
    {
      condition: invoiceData.serviceDTO.wheelAlignmentPrice,
      name: 'Wheel Alignment',
      price: invoiceData.serviceDTO.wheelAlignmentPrice,
      totalPrice: invoiceData.serviceDTO.wheelAlignmentPrice
    },
    {
      condition: invoiceData.serviceDTO.wheelBalancingQuantity,
      name: 'Wheel Balancing',
      quantity: invoiceData.serviceDTO.wheelBalancingQuantity,
      price: invoiceData.serviceDTO.wheelBalancingPrice,
      totalPrice: invoiceData.serviceDTO.wheelBalancingTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.weightBalancingQuantity,
      name: 'Balancing Weights',
      quantity: invoiceData.serviceDTO.weightBalancingQuantity,
      price: invoiceData.serviceDTO.weightBalancingPrice,
      totalPrice: invoiceData.serviceDTO.weightBalancingTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.tyreChangingQuantity,
      name: 'Tyre Changing',
      quantity: invoiceData.serviceDTO.tyreChangingQuantity,
      price: invoiceData.serviceDTO.tyreChangingPrice,
      totalPrice: invoiceData.serviceDTO.tyreChangingTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.tyreRotationQuantity,
      name: 'Tyre Rotation',
      quantity: invoiceData.serviceDTO.tyreRotationQuantity,
      price: invoiceData.serviceDTO.tyreRotationPrice,
      totalPrice: invoiceData.serviceDTO.tyreRotationTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.tyrePunctureQuantity,
      name: 'Tyre Puncture',
      quantity: invoiceData.serviceDTO.tyrePunctureQuantity,
      price: invoiceData.serviceDTO.tyrePuncturePrice,
      totalPrice: invoiceData.serviceDTO.tyrePunctureTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.simsQuantity,
      name: 'Sims',
      quantity: invoiceData.serviceDTO.simsQuantity,
      price: invoiceData.serviceDTO.simsPrice,
      totalPrice: invoiceData.serviceDTO.simsTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.nitrogenQuantity,
      name: 'Nitrogen',
      quantity: invoiceData.serviceDTO.nitrogenQuantity,
      price: invoiceData.serviceDTO.nitrogenPrice,
      totalPrice: invoiceData.serviceDTO.nitrogenTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.othersDescription,
      name: 'Other services',
      quantity: invoiceData.serviceDTO.othersDescription,
      price: invoiceData.serviceDTO.othersPrice,
      totalPrice: invoiceData.serviceDTO.othersTotalPrice
    },
    {
      condition: invoiceData.serviceDTO.freight,
      name: 'Freight Charge',
      totalPrice: invoiceData.serviceDTO.freightCharge
    }
  ].filter(row => row.condition);

  return (
    <div className="invoice-page">
      <Flex className="profile-user d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <Flex className="invoice-whole-container">
        <div className="left-section">
          <div className="in-containerpage">
            <div className="businessdetails d-flex justify-content-between align-items-top-center">
              <div className="imageclass">
                <img
                  className="img-fluid"
                  src={
                    dataTransferService.getBloburl() +
                    invoiceData.organisationProfile
                  }
                  alt="Invoice"
                  width={130}
                  height={130}
                />
              </div>
              <div className="details1">
                <p className="fs-14 fw-bold">
                  {dataTransferService.getSelectedOrganization()}
                </p>
                <p className="fs-12 fw-bold">
                  {dataTransferService.getSelectedBranch()}
                </p>
                <p className="fs-12 fw-bold">{invoiceData.branchAddress}</p>
                <p className="fs-12 fw-bold">
                  {invoiceData.branchMobileNumber}
                </p>
              </div>
            </div>

            <div className="details2 mt-3">
              <p className="fs-14 fw-bold">
                Invoice number : {invoiceData.invoiceSerialNumber}
              </p>
              <p className="fs-12 fw-bold">Date : {isoDateString}</p>
            </div>

            <div className="bill-container border border-light">
              <div className="tobill">
                <p className="fs-12 fw-bold">Bill To :</p>
                <p className="fs-14 fw-600">{invoiceData.customerName}</p>
                {invoiceData.gstinNumber && (
                  <p className="fs-14 fw-600">
                    GSTIN : {invoiceData.gstinNumber}
                  </p>
                )}
                <p className="fs-12 fw-bold pt-1">
                  {invoiceData.vehicleNumber}
                </p>
                <p className="fs-12 fw-bold pt-1">
                  {invoiceData.vehicleManufacturer}
                </p>
                <p className="fs-12 fw-bold pt-1">{invoiceData.vehicleModel}</p>
                <p className="fs-12 fw-bold pt-1">
                  {invoiceData.customerMobileNumber}
                </p>
                {invoiceData.serviceDTO.wheelAlignment === true && (
                  <>
                    <p className="fs-12 fw-bold pt-4">Note:</p>
                    <p className="fs-12 fw-500 pt-1 note-wrap">
                      For optimized and smooth rides, please schedule the next
                      wheel alignment once the current reading increases by
                      {invoiceData.vehicleType === 'CAR' ? ' 4500 ' : ' 8000 '}
                      kilometers or by {upcominAlertDate || 'dd/mm/yy'}.
                    </p>
                  </>
                )}
              </div>
              <table className="table custom-table mt-4">
                <thead>
                  <tr>
                    <th className="fw-bold">No</th>
                    <th className="fw-bold">Services</th>
                    <th className="fw-bold">Quantity</th>
                    <th className="fw-bold">Unit Price</th>
                    <th className="fw-bold">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceRows.map(row => (
                    <tr key={row.name}>
                      <td>{serviceRows.indexOf(row) + 1}</td>
                      <td>{row.name}</td>
                      <td>
                        {row.name === 'Freight Charge' ||
                        row.name === 'Wheel Alignment' ? (
                          ''
                        ) : (
                          <>
                            {row.quantity}
                            {row.name !== 'Other services' && (
                              <p className="dark-grayish-blue word-break">
                                Unit(s)
                              </p>
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        {row.name === 'Freight Charge' ||
                        row.name === 'Wheel Alignment' ||
                        row.name === 'Other services' ? (
                          ''
                        ) : (
                          <>₹ {row.price}</>
                        )}
                      </td>

                      <td>₹ {row.totalPrice}</td>
                    </tr>
                  ))}

                  <tr className="total-row">
                    <td colSpan="3"></td>
                    <td>
                      <span
                        className={
                          invoiceData.gstinNumber ? '' : 'fs-14 fw-bold'
                        }
                      >
                        {invoiceData.gstinNumber ? 'Sub Total' : 'Total'}
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          invoiceData.gstinNumber ? '' : 'fs-14 fw-bold'
                        }
                        style={{ fontFamily: 'Inter, sans-serif' }}
                      >
                        ₹ {invoiceData.amount.toFixed(0)}
                      </span>
                    </td>
                  </tr>
                  {invoiceData.gstinNumber && (
                    <>
                      <tr className="total-row">
                        <td colSpan="3"></td>
                        <td>
                          <span className="fw-500">CGST 9% </span>
                        </td>
                        <td className="fw-500">
                          ₹ {invoiceData.cgst.toFixed(0)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="3"></td>
                        <td>
                          <span className="fw-500">SGST 9% </span>
                        </td>
                        <td className="fw-500">
                          ₹ {invoiceData.sgst.toFixed(0)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="3"></td>
                        <td>
                          <span className="fs-14 fw-bold">Total</span>
                        </td>
                        <td className="fs-14 fw-bold">
                          ₹ {invoiceData.totalAmount.toFixed(0)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div>
              <p className="fs-12">Terms & Conditions:</p>
              <p className="fs-12 lh-base">
                1. There is no warranty for Wheel Alignment (or) any services.
              </p>
              <p className="fs-12 text-wrap lh-base">
                2. Our liability for any damages our tosses resulting from our
                services is limited to the cost of the service performed.we are
                not responsible any incidental, consequential, our Indirect
                damages.
              </p>
              <p className="fs-12 text-wrap lh-base">
                3. Customers are responsible from providing accurate information
                about their vehicle and ensuring that the vehicle is in a
                suitable condition for alignment. Any pre-cousting issues with
                the vehicle must be disclosed prior to service.
              </p>
            </div>
          </div>
        </div>

        <div className="right-section">
          <div className="right-container d-flex flex-column">
            <p className="h5">
              <strong>
                {paymentType ||
                invoiceData.paymentType === 'CASH' ||
                invoiceData.paymentType === 'CREDIT' ||
                invoiceData.paymentType === 'UPI' ||
                invoiceData.paymentType === 'CREDITCARD'
                  ? 'Customer Details'
                  : 'Next Service Alert'}
              </strong>
            </p>
            <hr />

            <div className="alert-payment-container d-flex flex-column">
              {(paymentType || invoiceData.paymentType) && (
                <>
                  <p className="mt-3">
                    Customer Name: {invoiceData.customerName}
                  </p>
                  <p className="mt-3">
                    Mobile Number: {invoiceData.customerMobileNumber}
                  </p>
                  <p className="mt-3">
                    Vehicle Number: {invoiceData.vehicleNumber}
                  </p>
                  <p className="mt-3">
                    Vehicle Model: {invoiceData.vehicleModel}
                  </p>
                  <p className="mt-3">
                    Payment Type:{' '}
                    {invoiceData.paymentType?.toLowerCase() || paymentType}
                  </p>

                  {(paymentType === 'credit' ||
                    invoiceData.paymentType === 'CREDIT') && (
                    <>
                      <p className="mt-3">
                        Credit Status:
                        <span
                          className={`ms-2 d-inline-block p-1 rounded border credit-status ${
                            isSettled
                              ? 'credit-status-settled'
                              : 'credit-status-unsettled'
                          }`}
                        >
                          {currentStatus}
                        </span>
                        <button
                          className="btn btn-link"
                          onClick={handleChangeCreditStatus}
                        >
                          Change
                        </button>
                      </p>
                      <button
                        className="save-btn2"
                        onClick={updateCreditStatus}
                      >
                        Update
                      </button>
                    </>
                  )}

                  <button className="save-btn2" onClick={handleSaveAndPrint}>
                    Print Invoice
                  </button>
                </>
              )}
            </div>

            {!paymentType && !invoiceData.paymentType && (
              <>
                <p>
                  Average Kms of Vehicle: {invoiceData.vehicleAveragekmsPerday}{' '}
                  kms/day
                </p>
                <p className="mt-3">
                  Next {invoiceData.vehicleOdometerReading} kms on:{' '}
                  {upcominAlertDate}
                </p>
                <select
                  ref={paymentTypeRef}
                  className="w-50 form-select mt-3"
                  defaultValue=""
                >
                  <option value="">Select Payment Type</option>
                  <option value="cash">Cash</option>
                  <option value="credit">Credit</option>
                  <option value="UPI">UPI</option>
                  <option value="CREDITCARD">CreditCard</option>
                </select>
                <button
                  className="save-btn"
                  onClick={() => {
                    if (!paymentTypeRef.current.value) {
                      alert('Please select a payment type.');
                    } else {
                      handlePaymentTypeChange();
                    }
                  }}
                >
                  Save Invoice
                </button>
              </>
            )}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default Invoice;
