import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Back = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <FontAwesomeIcon icon={faArrowLeft} onClick={goBack} className="pointer" />
  );
};
