import React, { useState, useEffect, useCallback } from 'react';
import DataTransferService from '../../core/service/dataTransferService';
import { Table } from 'react-bootstrap';
import ServiceRequest from '../../core/service/serviceRequest';
import Loader from '../../core/loader/loader';
import { PaginationComponent } from '../../common/pagination';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';
import { DropdownComponent } from '../../common/dropDown';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import SearchInput from '../../common/search';
import { Back } from '../../common/back';
import { numberFormatter } from '../../common/numberFormatter';

const ShowCreditHistory = () => {
  const selectOptions = [
    { id: 1, label: 'Newest', value: 'Newest' },
    { id: 2, label: 'Oldest', value: 'Oldest' }
  ];

  const [isFocused, setIsFocused] = useState(false);
  const dataTransferService = new DataTransferService();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Newest');
  const [creditData, setCreditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();

  const fetchCreditData = useCallback(
    (page, size, order) => {
      setLoading(true);
      setErrorMessage('');
      const serviceRequest = new ServiceRequest();
      serviceRequest
        .paymentStatus(page, size, organisationName, branchName, order)
        .then(response => {
          setLoading(false);
          if (response.data.responseCode === 200) {
            if (response.data.responseBody.creditTotal === 0.0) {
              return setErrorMessage('No services found');
            }
            setCreditData(response.data.responseBody.settledInvoices);
            setTotalItems(response.data.responseBody.totalItems);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchCreditData', error.response);
          setErrorMessage(error.response.data.responseMessage);
        });
    },
    [branchName, organisationName]
  );

  const searchCreditData = useCallback(
    (vehicleNumber, page, size) => {
      setLoading(true);
      setErrorMessage('');
      const serviceRequest = new ServiceRequest();
      serviceRequest
        .searchCreditDetails(
          vehicleNumber,
          page,
          size,
          branchName,
          organisationName
        )
        .then(response => {
          setLoading(false);
          if (response.data.responseCode === 200) {
            setCreditData(response.data.responseBody.settledInvoices);
            setTotalItems(response.data.responseBody.totalItems);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('searchCreditData', error.response);
          setErrorMessage(error.response.data.responseMessage);
        });
    },
    [branchName, organisationName]
  );

  useEffect(() => {
    if (searchTerm) {
      searchCreditData(searchTerm, pageIndex, pageSize);
    } else {
      fetchCreditData(pageIndex, pageSize, sortOption);
    }
  }, [
    pageIndex,
    pageSize,
    searchTerm,
    sortOption,
    fetchCreditData,
    searchCreditData
  ]);

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
    setPageIndex(0);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setPageIndex(0);
  };

  const handleSortChange = option => {
    setSortOption(option);
    setPageIndex(0);
  };

  const handleNavigate = () => {
    navigate('/select-vehicle');
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <div className="white-container mt-5 services-card-radius p-4">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="padding-heading fw-600 ps-2">Credit Services History</p>
        </Flex>

        <Flex className="pt-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
          />

          {!searchTerm && (
            <DropdownComponent
              value={sortOption}
              selected={
                <Flex className="d-flex align-items-center">
                  <span className="fs-12 fw-400 grayish-blue pe-2">
                    Sort by:
                  </span>
                  {sortOption}
                </Flex>
              }
              options={selectOptions}
              onSelect={handleSortChange}
              toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
            />
          )}
        </Flex>

        <div className="d-flex justify-content-end me-2 mt-3">
          <button
            className="btn btn-outline custom-btn-outline new-service-button"
            onClick={handleNavigate}
          >
            + New Service
          </button>
        </div>

        <div className="table-responsive">
          <Table hover>
            <thead>
              <tr>
                <th className="services-headers">Vehicle Number</th>
                <th className="services-headers">Invoice Number</th>
                <th className="services-headers">Category</th>
                <th className="services-headers">Amount</th>
                <th className="services-headers">Payment Type</th>
                <th className="services-headers">Invoice</th>
              </tr>
            </thead>

            <tbody>
              {errorMessage ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center text-danger">
                      {errorMessage}
                    </div>
                  </td>
                </tr>
              ) : (
                creditData?.map(service => (
                  <tr key={service.invoiceSerialNumber}>
                    <td className="p-3">{service.vehicleNumber}</td>
                    <td className="p-3">{service.invoiceSerialNumber}</td>
                    <td className="p-3">{service.vehicleType}</td>
                    <td className="p-3">{service.amount.toFixed(0)}</td>
                    <td className="p-3">{service.paymentType}</td>
                    <td className="p-3">
                      <Link
                        to={`/invoice/${service.invoiceSerialNumber}`}
                        style={{
                          color: '#127BFF',
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        <div className="mt-4 d-flex justify-content-between">
          <span className="grayish-blue fs-14 fw-500">
            {totalItems > 0
              ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                  (pageIndex + 1) * pageSize,
                  totalItems
                )} of ${numberFormatter(totalItems)} entries`
              : 'No entries available'}
          </span>

          <PaginationComponent
            prevPage={prevPage}
            nextPage={nextPage}
            isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
            isPrevDisabled={pageIndex === 0}
            pageIndex={pageIndex}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowCreditHistory;
