import Tabs from 'react-bootstrap/Tabs';
import { UilUserSquare } from '@iconscout/react-unicons';
import React, { useCallback, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import ManageUsers from '../admin-manager-access/manageUsers';
import ManageOrganisation from '../admin-manager-access/manageOrganisation';
import Settings from './settings';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import DataTransferService from '../../core/service/dataTransferService';

function UpdateProfile() {
  const [activeTab, setActiveTab] = useState('settings');
  const dataTransferService = new DataTransferService();
  const userRole = dataTransferService.getUserInfo().userRole;
  const [allBranchesReport, setAllBranchesReport] = useState(true);
  const handleTabChange = tabKey => {
    setActiveTab(tabKey);
  };

  const customStyles = {
    tabContainer: {
      padding: '1rem'
    },
    tabTitle: {
      paddingBottom: '0.5rem',
      cursor: 'pointer'
    },
    activeTab: {
      textDecoration: 'none',
      borderBottom: '3px solid #5932EA'
    },
    inactiveTab: {
      textDecoration: 'none'
    }
  };

  const getSelectedBranch = useCallback(() => {
    const dataTransferService = new DataTransferService();
    if (dataTransferService.getSelectedBranch() !== 'All Branches Report') {
      setAllBranchesReport(false);
    }
  }, []);

  useEffect(() => {
    getSelectedBranch();
  }, [getSelectedBranch]);

  return (
    <div className="background-container p-4">
      <div className="d-flex justify-content-between">
        <p className="fw-500 fs-4 mt-21">Settings</p>
        <Flex className="d-flex justify-content-end align-items-center pt-1">
          <Profile />
        </Flex>
      </div>

      <div className="user-profile-container p-4">
        <div>
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabChange}
            className="custom-tabs tabs-margin fs-6"
          >
            {/* Settings Tab */}
            <Tab
              eventKey="settings"
              title={
                <span
                  style={{
                    ...customStyles.tabTitle,
                    ...(activeTab === 'settings'
                      ? customStyles.activeTab
                      : customStyles.inactiveTab)
                  }}
                >
                  <UilUserSquare className="icon-size-profile" /> Account
                  Information
                </span>
              }
            >
              <Settings />
            </Tab>

            {/* Conditionally render Manage Organisation tab */}
            {(userRole === 'ADMIN' || userRole === 'MANAGER') &&
              !allBranchesReport && (
                <Tab
                  eventKey="manageOrganisation"
                  title={
                    <span
                      style={{
                        ...customStyles.tabTitle,
                        ...(activeTab === 'manageOrganisation'
                          ? customStyles.activeTab
                          : customStyles.inactiveTab)
                      }}
                    >
                      <UilUserSquare className="icon-size-profile" /> Manage
                      Organisations
                    </span>
                  }
                >
                  <ManageOrganisation />
                </Tab>
              )}

            {/* Conditionally render Manage Users tab */}
            {(userRole === 'ADMIN' || userRole === 'MANAGER') &&
              !allBranchesReport && (
                <Tab
                  eventKey="manageUsers"
                  title={
                    <span
                      style={{
                        ...customStyles.tabTitle,
                        ...(activeTab === 'manageUsers'
                          ? customStyles.activeTab
                          : customStyles.inactiveTab)
                      }}
                    >
                      <UilUserSquare className="icon-size-profile" /> Manage
                      Users
                    </span>
                  }
                >
                  <ManageUsers />
                </Tab>
              )}
          </Tabs>
        </div>
        <hr className="color" />
      </div>
    </div>
  );
}

export default UpdateProfile;
