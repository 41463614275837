import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProceedToLogin = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/login');
  };

  return (
    <div className="page-container">
      <div className="proceed-login p-4">
        <p className="heading-ff-poppins fw-600 fs-22">
          Password Updated.Please Proceed to login
        </p>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="button-styling fw-600 p-12"
            onClick={navigateToHome}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProceedToLogin;
