import ServiceRequest from '../core/service/serviceRequest';
import { handleErrorResponse } from '../core/tokenInterceptor/axiosInstance';
import { toast } from 'react-toastify';
import UserService from '../core/service/userService';
import ReturnTrackerRequest from '../core/service/returnTrackerRequest';

export const vehicleModel = (
  vehicleManufacturer,
  { setLoading, setResponseVehicleModel }
) => {
  const serviceRequest = new ServiceRequest();
  setLoading(true);
  serviceRequest
    .vehicleModels(vehicleManufacturer.toUpperCase())
    .then(response => {
      if (response.data.responseCode === 200) {
        setLoading(false);
        setResponseVehicleModel(
          response.data.responseBody.map(model => ({
            label: model,
            value: model.toLowerCase()
          }))
        );
      }
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('vehicle-model', error.response);
    });
};

export const confirmDelete = (
  selectedUser,
  organisationName,
  fetchUsers,
  setLoading,
  setShowDeleteModal,
  setUsers
) => {
  setLoading(true);
  const userService = new UserService();
  userService
    .deleteUser(selectedUser)
    .then(response => {
      if (response.data.responseCode === 200) {
        fetchUsers(organisationName);
        setUsers(prevUsers =>
          prevUsers.filter(user => user.userMobileNumber !== selectedUser)
        );
        toast.success(response.data.responseMessage, {
          theme: 'colored'
        });
      }
      setLoading(false);
      setShowDeleteModal(false);
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('delete-user', error.response);
      setShowDeleteModal(false);
    });
};

export const tyreModel = (
  tyreManufacturer,
  { setLoading, setResponseTyreModel }
) => {
  const returnTrackerRequest = new ReturnTrackerRequest();
  setLoading(true);
  returnTrackerRequest
    .tyreModels(tyreManufacturer.toUpperCase())
    .then(response => {
      if (response.data.responseCode === 200) {
        setLoading(false);
        setResponseTyreModel(
          response.data.responseBody.map(model => ({
            label: model,
            value: model.toLowerCase()
          }))
        );
      }
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('tyreModel', error.response);
    });
};
