import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import UserService from '../core/service/userService';
import { handleErrorResponse } from '../core/tokenInterceptor/axiosInstance';
import { forgotPasswordValidations } from '../validations/forms';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import Flex from '../common/flex';
import { Card } from 'react-bootstrap';
import Loader from '../core/loader/loader';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('email');
  const [userEmail, setUserEmail] = useState('');
  const [otpValues, setOtpValues] = useState(new Array(6).fill(''));
  const userService = new UserService();
  const navigate = useNavigate();

  const handleEmailSubmit = (values, { resetForm }) => {
    setLoading(true);
    userService
      .forgotPassword(values.userEmail)
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setUserEmail(values.userEmail);
          setStep('otp');
          toast.success(`${response.data.responseMessage}`, {
            theme: 'colored'
          });
          resetForm();
        }
      })
      .catch(error => {
        if (error.response.data.responseCode === 404) {
          setLoading(false);
          toast.error(`${error.response.data.responseMessage}`, {
            theme: 'colored'
          });
          return;
        }
        handleErrorResponse('forgot-password', error.response);
      });
  };

  const handleOTPSubmit = () => {
    const otp = otpValues.join('');
    if (otp.length < 6) {
      toast.error('Please enter a valid 6-digit OTP', { theme: 'colored' });
      return;
    }
    setLoading(true);
    userService
      .verifyOtp(userEmail, otp)
      .then(response => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          toast.success('OTP verified successfully', {
            theme: 'colored'
          });
          navigate(`/set-password?userEmail=${userEmail}`);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('verify-otp', error.response);
      });
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = element.value;
    setOtpValues(updatedOtpValues);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  return (
    <Flex className="justify-content-center align-items-center vh-100">
      {loading && <Loader loading={loading} />}
      <Card className="px-4 py-3 no-border">
        {step === 'email' ? (
          <Formik
            initialValues={{ userEmail: '' }}
            validationSchema={forgotPasswordValidations}
            onSubmit={handleEmailSubmit}
          >
            <Form>
              <h5 className="pt-3">Enter Email to Reset Password</h5>
              <div className="pt-3">
                <label htmlFor="userEmail">
                  Email <span className="color-star">*</span>
                </label>
                <Field
                  type="email"
                  name="userEmail"
                  id="userEmail"
                  placeholder="Please enter email"
                  className="pt-2"
                />
                <ErrorMessage name="userEmail">
                  {msg => <div className="text-danger">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="pt-3">
                <button
                  type="submit"
                  className="p-2 button-styling"
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        ) : (
          <div>
            <h5 className="pt-3">Enter the OTP sent to Email</h5>
            <div className="pt-3 d-flex justify-content-center">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="otp-input mx-1"
                  value={value}
                  onChange={e => handleOtpChange(e.target, index)}
                  onFocus={e => e.target.select()}
                  style={{
                    width: '2.5rem',
                    height: '2.5rem',
                    textAlign: 'center',
                    fontSize: '1.5rem'
                  }}
                />
              ))}
            </div>
            <div className="pt-3 d-flex align-items-center justify-content-center">
              <button
                onClick={handleOTPSubmit}
                className="p-2 button-styling"
                disabled={loading}
              >
                {'Verify OTP'}
              </button>
            </div>
          </div>
        )}
      </Card>
    </Flex>
  );
};

export default ForgotPassword;
