import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../common/flex';
import PropTypes from 'prop-types';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PaginationComponent = ({
  prevPage,
  nextPage,
  isPrevDisabled,
  isNextDisabled,
  pageIndex,
  totalItems,
  pageSize,
  onPageChange
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const generatePageNumbers = () => {
    const pages = [];
    const ellipsis = (
      <span key="ellipsis" className="mx-1">
        ...
      </span>
    );

    if (totalPages > 0) {
      pages.push(
        <Button
          key={1}
          size="sm"
          style={{
            backgroundColor: pageIndex === 0 ? '#5932EA' : '#f8f9fa',
            color: pageIndex === 0 ? '#fff' : '#000',
            borderColor: 'transparent'
          }}
          onClick={() => onPageChange(0)}
          className="mx-1"
        >
          1
        </Button>
      );
    }

    if (pageIndex < 3) {
      for (let i = 2; i <= Math.min(totalPages - 1, 5); i++) {
        pages.push(
          <Button
            key={i}
            size="sm"
            style={{
              backgroundColor: pageIndex + 1 === i ? '#5932EA' : '#f8f9fa',
              color: pageIndex + 1 === i ? '#fff' : '#000',
              borderColor: 'transparent'
            }}
            onClick={() => onPageChange(i - 1)}
            className="mx-1"
          >
            {i}
          </Button>
        );
      }

      if (totalPages > 5) {
        pages.push(ellipsis);
      }

      if (totalPages > 1) {
        pages.push(
          <Button
            key={totalPages}
            size="sm"
            style={{
              backgroundColor:
                pageIndex + 1 === totalPages ? '#5932EA' : '#f8f9fa',
              color: pageIndex + 1 === totalPages ? '#fff' : '#000',
              borderColor: 'transparent'
            }}
            onClick={() => onPageChange(totalPages - 1)}
            className="mx-1"
          >
            {totalPages}
          </Button>
        );
      }
    } else if (pageIndex >= totalPages - 1) {
      pages.push(ellipsis);
      for (let i = Math.max(totalPages - 4, 2); i <= totalPages - 1; i++) {
        pages.push(
          <Button
            key={i}
            size="sm"
            style={{
              backgroundColor: pageIndex + 1 === i ? '#5932EA' : '#f8f9fa',
              color: pageIndex + 1 === i ? '#fff' : '#000',
              borderColor: 'transparent'
            }}
            onClick={() => onPageChange(i - 1)}
            className="mx-1"
          >
            {i}
          </Button>
        );
      }
    } else {
      pages.push(ellipsis);
      for (let i = pageIndex - 1; i <= pageIndex + 1; i++) {
        if (i >= 2 && i <= totalPages - 1) {
          pages.push(
            <Button
              key={i}
              size="sm"
              style={{
                backgroundColor: pageIndex + 1 === i ? '#5932EA' : '#f8f9fa',
                color: pageIndex + 1 === i ? '#fff' : '#000',
                borderColor: 'transparent'
              }}
              onClick={() => onPageChange(i - 1)}
              className="mx-1"
            >
              {i}
            </Button>
          );
        }
      }

      if (totalPages > 1) {
        pages.push(
          <Button
            key={totalPages}
            size="sm"
            style={{
              backgroundColor:
                pageIndex + 1 === totalPages ? '#5932EA' : '#f8f9fa',
              color: pageIndex + 1 === totalPages ? '#fff' : '#000',
              borderColor: 'transparent'
            }}
            onClick={() => onPageChange(totalPages - 1)}
            className="mx-1"
          >
            {totalPages}
          </Button>
        );
      }
    }

    return pages;
  };

  return (
    <div className="bottom-padding">
      <Flex className="justify-content-end">
        <Button
          size="sm"
          onClick={prevPage}
          disabled={isPrevDisabled}
          style={{
            backgroundColor: isPrevDisabled ? '#f8f9fa' : '#5932EA',
            color: isPrevDisabled ? '#000' : '#fff',
            borderColor: 'transparent'
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="pointer" />
        </Button>

        {generatePageNumbers()}

        <Button
          size="sm"
          className="ms-2"
          onClick={nextPage}
          disabled={isNextDisabled}
          style={{
            backgroundColor: isNextDisabled ? '#f8f9fa' : '#5932EA',
            color: isNextDisabled ? '#000' : '#fff',
            borderColor: 'transparent'
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} className="pointer" />
        </Button>
      </Flex>
    </div>
  );
};

PaginationComponent.propTypes = {
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  isPrevDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};
