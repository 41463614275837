import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import React from 'react';

export const DropdownComponent = ({
  value = 'all',
  selected = 'all',
  options,
  onSelect,
  toggleClassName = ''
}) => {
  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle className={toggleClassName}>{selected}</Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map(option => (
          <Dropdown.Item
            key={option.id}
            eventKey={option.value}
            className={value === option.value ? 'selected-item' : ''}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownComponent.propTypes = {
  value: PropTypes.string,
  selected: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string
};

export const DisabledDropdownComponent = ({
  selected = 'Select an option',
  toggleClassName = ''
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle disabled className={toggleClassName}>
        {selected}
      </Dropdown.Toggle>
      <Dropdown.Menu></Dropdown.Menu>
    </Dropdown>
  );
};

DisabledDropdownComponent.propTypes = {
  selected: PropTypes.string,
  toggleClassName: PropTypes.string
};

export const OrgChangeDropDown = ({
  selected = 'all',
  options,
  onSelect,
  toggleClassName = '',
  isOpen,
  onToggle
}) => {
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}` : text;
  };
  return (
    <Dropdown show={isOpen} onToggle={onToggle}>
      <Dropdown.Toggle className={toggleClassName}>
        {truncateText(selected, 22)}
      </Dropdown.Toggle>
      <Dropdown.Menu className="mt-4">
        {options.map(option => (
          <Dropdown.Item
            key={option.id}
            eventKey={option.value}
            onClick={() => onSelect(option.value)}
            className={selected === option.value ? 'selected-item' : ''}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

OrgChangeDropDown.propTypes = {
  selected: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};
