import React, { useState, useEffect, useCallback } from 'react';
import {
  DisabledDropdownComponent,
  DropdownComponent
} from '../../common/dropDown';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { inviteUserValidations } from '../../validations/forms';
import UserService from '../../core/service/userService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import Loader from '../../core/loader/loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DataTransferService from '../../core/service/dataTransferService';

const InviteForm = () => {
  const userService = new UserService();
  const [loading, setLoading] = useState(false);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [branches, setBranches] = useState([]);
  const organisationDTO = JSON.parse(localStorage.getItem('organisationDTO'));
  const navigate = useNavigate();
  const dataTransferService = new DataTransferService();
  const selectedOrg = dataTransferService.getSelectedOrganization();

  const handleInviteUser = () => {
    navigate('/invite-users');
  };

  const fetchBranches = useCallback(organisationName => {
    setLoading(true);
    const userService = new UserService();
    userService
      .fetchBranches(organisationName)
      .then(response => {
        if (response.data.responseCode === 200) {
          const branchData = response.data.responseBody.map(
            (branch, index) => ({
              id: index + 1,
              name: branch.branchName,
              address: branch.branchAddress,
              phone: branch.branchMobileNumber
            })
          );

          setBranches(branchData);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('branches-by', error.response);
      });
  }, []);

  useEffect(() => {
    const dataTransferService = new DataTransferService();
    if (organisationDTO) {
      const organisationName = organisationDTO.organisationName;

      setOrganisationOptions([
        {
          id: 1,
          label: organisationName,
          value: organisationName
        }
      ]);

      const branchData = organisationDTO.branchDTOList.map((branch, index) => ({
        id: index + 1,
        name: branch.branchName,
        address: branch.branchAddress,
        phone: branch.branchMobileNumber
      }));

      setBranches(branchData);
    } else {
      const selectedOrg = dataTransferService.getSelectedOrganization();
      if (selectedOrg) {
        setOrganisationOptions([
          {
            id: 1,
            label: selectedOrg,
            value: selectedOrg
          }
        ]);
        fetchBranches(selectedOrg);
      }
    }
  }, [fetchBranches, organisationDTO]);

  const roleOptions = [
    { id: 1, label: 'Manager', value: 'Manager' },
    { id: 2, label: 'Store_Supervisor', value: 'Store_Supervisor' }
  ];

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Formik
        initialValues={{
          userName: '',
          userEmail: '',
          userRole: '',
          organisationName:
            organisationOptions.length > 0 ? organisationOptions[0].value : '',
          branchNames: [],
          userMobileNumber: ''
        }}
        validationSchema={inviteUserValidations}
        onSubmit={values => {
          setLoading(true);
          const orgName = organisationDTO
            ? organisationDTO.organisationName
            : selectedOrg;
          const transformedValues = {
            ...values,
            organisationName: orgName,
            userRole: values.userRole.toUpperCase(),
            branchNames: values.branchNames.map(id => {
              const branch = branches.find(branch => branch.id === id);
              return branch ? branch.name : '';
            })
          };
          userService
            .inviteUser(transformedValues)
            .then(response => {
              if (response.data.responseCode === 201) {
                setLoading(false);
                toast.success(`User invited successfully ${values.userName}`, {
                  theme: 'colored'
                });
                handleInviteUser();
              }
            })
            .catch(error => {
              setLoading(false);
              handleErrorResponse('admin-invites', error.response);
            });
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex align-items-center justify-content-center full-height">
              <div className="view-branches-container p-4">
                <p className="fs-4 fw-600">Invite Employees</p>
                <p className="fs-5 fw-500 mt-4">Employee Details</p>

                <div className="d-flex align-items-center justify-content-start mt-4">
                  <div className="me-4" style={{ width: '300px' }}>
                    <label className="email-text">
                      Enter Employee Name <span className="color-star">*</span>
                    </label>
                    <Field
                      type="text"
                      name="userName"
                      placeholder="Enter employee name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>

                  <div style={{ width: '300px' }}>
                    <label className="email-text">
                      Enter Employee Email <span className="color-star">*</span>
                    </label>
                    <Field
                      type="email"
                      name="userEmail"
                      placeholder="Enter employee email address"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="userEmail"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-start mt-4">
                  <div style={{ width: '300px' }}>
                    <label className="email-text">
                      Enter Employee Mobile Number{' '}
                      <span className="color-star">*</span>
                    </label>
                    <Field
                      type="text"
                      name="userMobileNumber"
                      placeholder="Enter employee mobile number"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="userMobileNumber"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>
                </div>

                <div className="note-styling fs-14 fw-normal">
                  Note : This employee can access the billing data , create new
                  bills for services offered.
                </div>

                <div className="mt-4">
                  <p className="email-text">
                    Employee Role<span className="color-star">*</span>
                  </p>
                  <DropdownComponent
                    selected={values.userRole || 'Select Role'}
                    options={roleOptions}
                    onSelect={selectedOption =>
                      setFieldValue('userRole', selectedOption)
                    }
                    toggleClassName="fs-14 fw-semibold custom-invite-dropdown custom-arrow"
                  />
                  <ErrorMessage
                    name="userRole"
                    component="div"
                    className="error-text text-danger"
                  />
                </div>

                <div className="mt-4">
                  <p className="email-text">
                    Select Organization<span className="color-star">*</span>
                  </p>
                  {organisationDTO ? (
                    <DisabledDropdownComponent
                      toggleClassName="fs-14 fw-bold .disabled-dropdown custom-arrow "
                      selected={organisationDTO.organisationName}
                    />
                  ) : selectedOrg ? (
                    <DisabledDropdownComponent
                      toggleClassName="fs-14 fw-bold disabled-dropdown custom-arrow w-25"
                      selected={selectedOrg}
                    />
                  ) : (
                    <p className="text-danger">No organization selected</p>
                  )}
                </div>

                <div className="select-branches-outer-container mt-4 d-flex flex-column p-5 pb-32">
                  <div className="d-flex align-items-center justify-content-start">
                    {branches.map(branch => (
                      <div
                        key={branch.id}
                        className={`me-3 select-branches-inner-container pt-3 ps-4 d-flex align-items-center justify-content-evenly branch-data ${
                          values.branchNames.includes(branch.id)
                            ? 'selected'
                            : ''
                        }`}
                      >
                        <div className="branch-fields">
                          <p className="fs-6 fw-500">{branch.name}</p>
                          <p className="fs-15 fw-400">{branch.address}</p>
                          <p className="fs-14 fw-400">Ph: {branch.phone}</p>
                        </div>
                        <input
                          type="checkbox"
                          checked={values.branchNames.includes(branch.id)}
                          onChange={() => {
                            if (values.branchNames.includes(branch.id)) {
                              setFieldValue(
                                'branchNames',
                                values.branchNames.filter(
                                  id => id !== branch.id
                                )
                              );
                            } else {
                              setFieldValue('branchNames', [
                                ...values.branchNames,
                                branch.id
                              ]);
                            }
                          }}
                          className="ms-3"
                        />
                      </div>
                    ))}
                  </div>
                  <ErrorMessage
                    name="branchNames"
                    component="div"
                    className="error-text text-danger"
                  />
                </div>

                <div className="d-flex align-items-center justify-content-end mt-5">
                  <button type="submit" className="p-2 button-teal w-25">
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteForm;
