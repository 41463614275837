import * as Yup from 'yup';

export const loginValidations = Yup.object({
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  userPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
});

export const inviteUserValidations = Yup.object({
  userName: Yup.string()
    .min(2, 'Employee name must be at least 2 characters')
    .required('Employee name is required')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Employee email is required'),
  userRole: Yup.string().required('Please select an employee role'),
  branchNames: Yup.array()
    .min(1, 'Please select at least one branch')
    .required('Branch selection is required'),
  userMobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit mobile number')
    .required('Employee mobile number is required')
});

export const organisationValidations = Yup.object({
  organisationName: Yup.string()
    .required('Business name is required.')
    .min(6, 'Business name must be at least 6 characters.')
    .max(22, 'Business name cannot exceed 22 characters')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  numLocations: Yup.number().required(
    'Number of business locations are required.'
  ),
  organisationProfile: Yup.mixed()
    .required('An image is required.')
    .test(
      'fileType',
      'Unsupported file format. Upload a valid image file.',
      value =>
        !value || (value && ['image/jpeg', 'image/png'].includes(value.type))
    )
});

export const createBranchesValidation = Yup.object({
  branchName: Yup.string()
    .required('Branch Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  branchAddress: Yup.string().required('Location is required'),
  branchMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  storeLink: Yup.string()
    .required('Store Link is required')
    .url('Invalid URL format')
});

export const setPasswordValidations = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character'
    )
    .required('Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export const heavyVehicleBillingValidations = Yup.object().shape({
  customerName: Yup.string()
    .required('Customer Name is required')
    .min(6, 'Customer Name must be at least 6 characters long')
    .matches(/^[A-Za-z.\s]+$/, 'Name should not contain numbers'),
  customerMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  driverMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  vehicleNumber: Yup.string()
    .required('Vehicle Number is required')
    .min(8, 'Vehicle Number must be at least 8 characters long'),
  vehicleManufacturer: Yup.string().required(
    'Vehicle Manufacturer is required'
  ),
  customVehicleManufacturer: Yup.string().when('vehicleManufacturer', {
    is: 'Other',
    then: () =>
      Yup.string().required('Custom Vehicle Manufacturer is required'),
    otherwise: () => Yup.string()
  }),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
  customVehicleModel: Yup.string().when('vehicleModel', {
    is: 'Other',
    then: () => Yup.string().required('Custom Vehicle Model is required'),
    otherwise: () => Yup.string()
  }),
  numberOfVehicleWheels: Yup.string().required('Number of Wheels is required'),
  vehicleOdometerReading: Yup.number().required(
    'Vehicle Odometer Reading is required'
  ),
  vehicleAverageKmsPerDay: Yup.number().required(
    "Average KM's Per Day is required"
  ),
  gstinNumber: Yup.string().when('useGstInvoice', {
    is: true,
    then: () => Yup.string().required('GSTIN Number is required'),
    otherwise: () => Yup.string()
  }),
  businessName: Yup.string().when('useGstInvoice', {
    is: true,
    then: () =>
      Yup.string()
        .required('Business Name is required')
        .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
    otherwise: () => Yup.string()
  }),
  services: Yup.object().shape({
    wheelAlignment: Yup.object().shape({
      wheelAlignmentPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    wheelBalancing: Yup.object().shape({
      wheelBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    weightBalancing: Yup.object().shape({
      weightBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      weightBalancingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    tyreChanging: Yup.object().shape({
      tyreChangingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      tyreChangingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    tyreRotation: Yup.object().shape({
      tyreRotationPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      tyreRotationQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    tyrePuncture: Yup.object().shape({
      tyrePuncturePrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      tyrePunctureQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    sims: Yup.object().shape({
      simsPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      simsQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    nitrogen: Yup.object().shape({
      nitrogenPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      nitrogenQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    others: Yup.object().shape({
      othersPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      othersQuantity: Yup.string().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(10, 'Quantity cannot be less than 10 charcters')
            .max(20, 'Quantity cannot be greater than 20 charcters'),
        otherwise: () => Yup.string()
      })
    }),
    freight: Yup.object().shape({
      selected: Yup.string()
        .required('Freight status is required')
        .oneOf(['Yes', 'No'], 'Please select either Yes or No'),
      freightCharge: Yup.number().when('selected', {
        is: 'Yes',
        then: () =>
          Yup.number()
            .required('Freight Charge is required')
            .min(0, 'Freight Charge cannot be negative'),
        otherwise: () => Yup.number().nullable()
      })
    })
  })
});

export const wheelerBillingValidations = Yup.object().shape({
  customerName: Yup.string()
    .required('Customer Name is required')
    .min(2, 'Customer Name must be at least 2 characters long')
    .matches(/^[A-Za-z.\s]+$/, 'Name should not contain numbers'),
  customerMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  driverMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  vehicleNumber: Yup.string()
    .required('Vehicle Number is required')
    .min(2, 'Vehicle Number must be at least 2 characters long'),
  vehicleManufacturer: Yup.string().required(
    'Vehicle Manufacturer is required'
  ),
  customVehicleManufacturer: Yup.string().when('vehicleManufacturer', {
    is: 'Other',
    then: () =>
      Yup.string().required('Custom Vehicle Manufacturer is required'),
    otherwise: () => Yup.string()
  }),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
  customVehicleModel: Yup.string().when('vehicleModel', {
    is: 'Other',
    then: () => Yup.string().required('Custom Vehicle Model is required'),
    otherwise: () => Yup.string()
  }),
  vehicleOdometerReading: Yup.number().required(
    'Vehicle Odometer Reading is required'
  ),
  vehicleAverageKmsPerDay: Yup.number().required(
    "Average KM's Per Day is required"
  ),
  gstinNumber: Yup.string().when('useGstInvoice', {
    is: true,
    then: () => Yup.string().required('GSTIN Number is required'),
    otherwise: () => Yup.string()
  }),
  businessName: Yup.string().when('useGstInvoice', {
    is: true,
    then: () => Yup.string().required('Business Name is required'),
    otherwise: () => Yup.string()
  }),
  services: Yup.object().shape({
    wheelAlignment: Yup.object().shape({
      wheelAlignmentPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    wheelBalancing: Yup.object().shape({
      wheelBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    weightBalancing: Yup.object().shape({
      weightBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      weightBalancingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    tyreChanging: Yup.object().shape({
      tyreChangingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      tyreChangingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    tyreRotation: Yup.object().shape({
      tyreRotationPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      tyreRotationQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    tyrePuncture: Yup.object().shape({
      tyrePuncturePrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      tyrePunctureQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    sims: Yup.object().shape({
      simsPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      simsQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    nitrogen: Yup.object().shape({
      nitrogenPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      nitrogenQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),

    others: Yup.object().shape({
      othersPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      othersQuantity: Yup.string().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(10, 'Quantity cannot be less than 10 charcters')
            .max(20, 'Quantity cannot be greater than 20 charcters'),
        otherwise: () => Yup.string()
      })
    })
  })
});

export const forgotPasswordValidations = Yup.object({
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required')
});

export const verifyOTP = Yup.object({
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  otp: Yup.string()
    .matches(/^\d{6}$/, 'Please enter a valid 6-digit OTP')
    .required('OTP is required')
});

export const newReturnTrackerValidations = Yup.object({
  serialNumber: Yup.string()
    .required('Serial Number is required')
    .matches(
      /^[A-Za-z0-9]+$/,
      'Serial Number should contain only alphanumeric characters'
    )
    .max(13, 'Serial Number cannot exceed 13 characters'),
  manufacturerReferenceId: Yup.string()
    .required('Manufacturer Reference Id is required')
    .min(3, 'Reference ID must be at least 3 characters')
    .max(13, 'Reference Id cannot exceed 13 characters'),

  tyreManufacturer: Yup.string().required('Please Select a Tyre Manufacturer'),
  customTyreManufacturer: Yup.string().when('tyreManufacturer', {
    is: 'Other',
    then: () => Yup.string().required('Custom TyreManufacturer is required'),
    otherwise: () => Yup.string()
  }),

  tyreModel: Yup.string().required('Please Select a Tyre Model'),

  customTyreModel: Yup.string().when('tyreModel', {
    is: 'Other',
    then: () => Yup.string().required('Custom Tyre Model is required'),
    otherwise: () => Yup.string()
  }),

  customerName: Yup.string()
    .required('Customer Name is required')
    .min(6, 'Customer Name must be at least 6 characters'),
  customerMobileNumber: Yup.string()
    .required('Customer Mobile Number is required')
    .matches(/^\d{10}$/, 'Customer Mobile Number must be exactly 10 digits'),
  returnStatus: Yup.string().required('Please Select a Return Status')
});

export const editReturnTrackerValidations = Yup.object({
  returnStatus: Yup.string().required('Please Select a Return Status')
});

export const sendBulkAlertsValidations = Yup.object({
  vehicleType: Yup.string().required('Vehicle type is required'),
  vehicleManufacturer: Yup.string().required(
    'Vehicle manufacturer is required'
  ),
  customVehicleManufacturer: Yup.string().when('vehicleManufacturer', {
    is: 'Other',
    then: () =>
      Yup.string().required('Custom vehicle manufacturer is required'),
    otherwise: () => Yup.string()
  }),
  vehicleModel: Yup.string().required('Vehicle model is required'),
  customVehicleModel: Yup.string().when('vehicleModel', {
    is: 'Other',
    then: () => Yup.string().required('Custom vehicle model is required'),
    otherwise: () => Yup.string()
  })
});

export const updateUserProfileValidations = Yup.object({
  userName: Yup.string()
    .required('User Name is required')
    .min(6, 'User Name must be at least 6 characters'),
  userProfile: Yup.mixed()
    .required('An image is required.')
    .test(
      'fileType',
      'Unsupported file format. Upload a valid image file.',
      value =>
        !value || (value && ['image/jpeg', 'image/png'].includes(value.type))
    )
});

export const editUserValidations = Yup.object({
  userName: Yup.string()
    .min(2, 'Employee name must be at least 2 characters')
    .required('Employee name is required')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Employee email is required'),
  userRole: Yup.string().required('Please select an employee role'),
  branchNames: Yup.array()
    .min(1, 'Please select at least one branch')
    .required('Branch selection is required'),
  userMobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit mobile number')
    .required('Employee mobile number is required')
});
