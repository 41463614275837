import { Appconfig } from '../config/appConfig';
import axiosInstance from '../tokenInterceptor/axiosInstance';

export class StorageService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'upload';
  }

  uploadImage(file) {
    const formData = new FormData();
    formData.append('file', file);
    return axiosInstance.post(`${this.Api}/image`, formData, {
      observe: 'response',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default StorageService;
