import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SubtleBadge = ({ bg = 'primary', pill, children, className }) => {
  return (
    <div
      className={classNames(className, `badge badge-subtle-${bg}`, {
        'rounded-pill': pill
      })}
    >
      {children}
    </div>
  );
};

SubtleBadge.propTypes = {
  bg: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default SubtleBadge;

export const statusByColor = status => {
  if (typeof status === 'string') {
    switch (status.toUpperCase()) {
      case 'PASSED_QC':
        return 'success';
      case 'FAILED_QC':
        return 'danger';
      case 'IN_PROCESS':
        return 'warning';
      default:
        return 'primary';
    }
  }
  return 'primary';
};

export const toPascalWord = status => {
  if (typeof status === 'string') {
    switch (status.toUpperCase()) {
      case 'IN_PROCESS':
        return 'In Process';
      case 'PASSED_QC':
        return 'Passed QC';
      case 'FAILED_QC':
        return 'Failed QC';
      default:
        return 'Primary';
    }
  }
  return 'Primary';
};
