import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Loader from '../../core/loader/loader';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import DataTransferService from '../../core/service/dataTransferService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { sendBulkAlertsValidations } from '../../validations/forms';
import { toast } from 'react-toastify';
import UserService from '../../core/service/userService';
import { InputField, SelectField } from '../../validations/inputFields';

const SendBulkAlerts = () => {
  const messageContainerStyle = {
    backgroundColor: '#E5FFD8',
    borderRadius: '0.625rem',
    padding: '1rem',
    width: '24.5rem'
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const initialValues = {
    vehicleType: '',
    vehicleManufacturer: '',
    customVehicleManufacturer: '',
    vehicleModel: '',
    customVehicleModel: ''
  };

  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const userService = new UserService();

  const fetchCount = vehicleDTO => {
    setLoadingPage(true);
    userService
      .fetchCountToSendBulkAlerts(organisationName, branchName, vehicleDTO)
      .then(response => {
        setLoadingPage(false);
        if (response?.data?.responseCode === 200) {
          const alertCount = response.data.responseBody;
          setAlertMessage(() => {
            const manufacturer =
              vehicleDTO.vehicleManufacturer === 'OTHER'
                ? vehicleDTO.customVehicleManufacturer
                : vehicleDTO.vehicleManufacturer;

            const model =
              vehicleDTO.vehicleModel === 'OTHER'
                ? vehicleDTO.customVehicleModel
                : vehicleDTO.vehicleModel;

            return `${alertCount} Results Found For ${manufacturer} ${model}`;
          });
          toast.success('Bulk alerts count fetched successfully!');
        }
      })
      .catch(error => {
        setLoadingPage(false);
        handleErrorResponse('fetchCountToSendBulkAlerts', error.response);
      });
  };

  const handleSearch = (values, { setSubmitting }) => {
    const formattedData = {
      vehicleType: values.vehicleType?.toUpperCase(),
      vehicleManufacturer:
        values.vehicleManufacturer === 'OTHER'
          ? 'OTHER'
          : values.vehicleManufacturer.toUpperCase(),
      customVehicleManufacturer:
        values.vehicleManufacturer === 'OTHER'
          ? values.customVehicleManufacturer.toUpperCase()
          : '',
      vehicleModel: values.vehicleModel.toUpperCase(),
      customVehicleModel:
        values.customVehicleModel === 'OTHER'
          ? 'OTHER'
          : values.customVehicleModel.toUpperCase()
    };

    fetchCount(formattedData);
    setSubmitting(false);
  };

  const handleSendAlerts = vehicleDTO => {
    setLoadingPage(true);
    userService
      .sendBulkAlertsBasedOnVehicle(organisationName, branchName, vehicleDTO)
      .then(response => {
        setLoadingPage(false);
        if (response.data.responseCode === 200) {
          setLoadingPage(false);
          toast.success('Reminder sent successfully!', { theme: 'colored' });
        }
      })
      .catch(error => {
        setLoadingPage(false);
        handleErrorResponse('sendBulkAlerts', error.response);
      });
  };

  return (
    <div className="p-4">
      {loadingPage && <Loader loading={loadingPage} />}
      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>
      <div className="alerts-bg-container p-4">
        <div className="row">
          <div className="col-12">
            <p className="bulk-alerts-heading mb-5">Bulk Alerts</p>
            <div>
              <p className="fs-6 bulk-alerts-heading mb-3">
                Select the fields to send alerts
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={sendBulkAlertsValidations}
                onSubmit={(values, { setSubmitting }) => {
                  handleSearch(values, { setSubmitting });
                }}
              >
                {formik => (
                  <Form>
                    <div className="row">
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Type"
                          id="vehicleType"
                          name="vehicleType"
                          options={['CAR', 'TRUCK']}
                          className="form-select"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Manufacturer"
                          id="vehicleManufacturer"
                          name="vehicleManufacturer"
                          options={[
                            'TOYOTA',
                            'HONDA',
                            'FORD',
                            'BMW',
                            'AUDI',
                            'OTHER'
                          ]}
                          className="form-select"
                        />
                        {formik.values.vehicleManufacturer === 'OTHER' && (
                          <div className="mt-3 mb-3">
                            <InputField
                              label="Specify Vehicle Manufacturer"
                              id="customVehicleManufacturer"
                              name="customVehicleManufacturer"
                              type="text"
                              placeholder="Enter Vehicle Manufacturer"
                              className="form-control"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Model"
                          id="vehicleModel"
                          name="vehicleModel"
                          options={['COROLLA', 'CIVIC', 'MUSTANG', 'OTHER']}
                          className="form-select"
                        />
                        {formik.values.vehicleModel === 'OTHER' && (
                          <div className="mt-3">
                            <InputField
                              label="Specify Vehicle Model"
                              id="customVehicleModel"
                              name="customVehicleModel"
                              type="text"
                              placeholder="Vehicle Model"
                              className="form-control"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-2 d-flex justify-content-end button-search">
                        <button
                          type="submit"
                          className="button-indigo button-indigo-height"
                        >
                          Search
                        </button>
                      </div>
                      <h6 className="strong-blue">{alertMessage}</h6>
                    </div>
                    <div className="mt-5">
                      <h6>Message Preview:</h6>
                      <div className="mt-4" style={messageContainerStyle}>
                        <h6>Greetings from {organisationName}</h6>
                        <h6 className="mt-4">Dear Customer,</h6>
                        <p className="fs-14 fw-500">
                          We’re reaching out from {organisationName} with an
                          update on items suited for your{' '}
                          {formik.values.vehicleType} Model:{' '}
                          {formik.values.vehicleModel === 'OTHER'
                            ? formik.values.customVehicleModel
                            : formik.values.vehicleModel}
                          , Manufacturer:{' '}
                          {formik.values.vehicleManufacturer === 'OTHER'
                            ? formik.values.customVehicleManufacturer
                            : formik.values.vehicleManufacturer}
                          . We have limited stock available for these items, so
                          please visit us at your convenience if you’d like to
                          explore your options.
                        </p>
                        <h6 className="mt-4">Best regards,</h6>
                        <h6>{organisationName}</h6>
                      </div>
                    </div>
                    <div className="pt-4">
                      <button
                        type="button"
                        className="p-2 button-styling w-25"
                        onClick={() => {
                          const count = parseInt(
                            alertMessage.split(' ')[0],
                            10
                          );

                          if (count === 0) {
                            toast.error('No results were found.', {
                              theme: 'colored'
                            });
                            return;
                          }

                          if (
                            !formik.values.vehicleType ||
                            !formik.values.vehicleManufacturer ||
                            (!formik.values.customVehicleManufacturer &&
                              formik.values.vehicleManufacturer === 'OTHER') ||
                            !formik.values.vehicleModel ||
                            (!formik.values.customVehicleModel &&
                              formik.values.vehicleModel === 'OTHER')
                          ) {
                            toast.error(
                              'Please select the filtration fields to send a reminder!',
                              {
                                theme: 'colored'
                              }
                            );
                          } else {
                            const formattedData = {
                              vehicleType:
                                formik.values.vehicleType?.toUpperCase(),
                              vehicleManufacturer:
                                formik.values.vehicleManufacturer?.toUpperCase() ===
                                'OTHER'
                                  ? 'OTHER'
                                  : formik.values.vehicleManufacturer?.toUpperCase(),
                              customVehicleManufacturer:
                                formik.values.vehicleManufacturer?.toUpperCase() ===
                                'OTHER'
                                  ? formik.values.customVehicleManufacturer?.toUpperCase()
                                  : '',
                              vehicleModel:
                                formik.values.vehicleModel?.toUpperCase() ===
                                'OTHER'
                                  ? 'OTHER'
                                  : formik.values.vehicleModel?.toUpperCase(),
                              customVehicleModel:
                                formik.values.vehicleModel?.toUpperCase() ===
                                'OTHER'
                                  ? formik.values.customVehicleModel?.toUpperCase()
                                  : ''
                            };
                            handleSendAlerts(formattedData);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="me-3 rotate-up"
                        />
                        Send Reminder Now
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendBulkAlerts;
