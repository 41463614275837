import React, { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import { loginValidations } from '../validations/forms';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../core/service/userService';
import { handleErrorResponse } from '../core/tokenInterceptor/axiosInstance';
import Loader from '../core/loader/loader';
import DataTransferService from '../core/service/dataTransferService';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const userService = useMemo(() => new UserService(), []);
  const dataTransferService = new DataTransferService();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    setShowPassword(prevState => !prevState);
  };
  const formik = useFormik({
    initialValues: {
      userEmail: '',
      userPassword: ''
    },
    validationSchema: loginValidations,
    onSubmit: values => {
      setLoading(true);
      userService
        .login(values)
        .then(response => {
          if (response.data.responseCode === 200) {
            setLoading(false);
            dataTransferService.setUuid(response.data.token);
            const { userEmail, userName, userRole, userProfile } =
              response.data.responseBody.user;
            dataTransferService.setUserInfo(
              userEmail,
              userName,
              userRole,
              userProfile
            );
            if (response.data.responseBody.organisations.length > 0) {
              dataTransferService.setOrgAndBranches(
                response.data.responseBody.organisations
              );
              navigate('/');
            } else {
              navigate('/create-organisation');
            }
          }
        })
        .catch(error => {
          setLoading(false);
          if (error.response.data.responseCode === 404) {
            return toast.error(error.response.data.responseMessage);
          }
          handleErrorResponse('user-login', error.response);
        });
    }
  });

  const navigate = useNavigate();
  const navigateToForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="page-container full-height">
      {loading && <Loader loading={loading} />}

      <div className="login-container p-4">
        <div className="mb-5">
          <h2 className="login-text">Log In</h2>
        </div>

        <div className="form-container">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label className="email-text" htmlFor="email">
                Email<span className="color-star">*</span>
              </label>
              <input
                type="email"
                name="userEmail"
                id="email"
                placeholder="Enter your email address"
                value={formik.values.userEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.userEmail && formik.errors.userEmail
                    ? 'error'
                    : ''
                }
              />
              {formik.touched.userEmail && formik.errors.userEmail ? (
                <div className="error">{formik.errors.userEmail}</div>
              ) : null}
            </div>

            <div className="mb-3 password-container">
              <label className="pw-text" htmlFor="password">
                Password<span className="color-star">*</span>
              </label>
              <div className="input-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="userPassword"
                  id="password"
                  placeholder="Enter your password"
                  value={formik.values.userPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched.userPassword && formik.errors.userPassword
                      ? 'error'
                      : ''
                  }
                />
                <span className="icons" onClick={handleClick}>
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </span>
              </div>
              {formik.touched.userPassword && formik.errors.userPassword ? (
                <div className="error">{formik.errors.userPassword}</div>
              ) : null}
            </div>
            <div className="d-flex justify-content-end">
              <p
                className="forgot-password pointer"
                onClick={navigateToForgotPassword}
              >
                Forgot password?
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="p-2 button-styling"
                disabled={loading}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
