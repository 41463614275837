import { Appconfig } from '../config/appConfig';
import axiosInstance from '../tokenInterceptor/axiosInstance';

export class ReturnTrackerService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'return-tracker';
  }

  createReturnTracker(body, branchName, organisationName) {
    return axiosInstance.post(this.Api, body, {
      params: { branchName, organisationName }
    });
  }

  updateReturnTracker(
    organisationName,
    branchName,
    serialNumber,
    returnStatus
  ) {
    return axiosInstance.put(this.Api, null, {
      params: { organisationName, branchName, serialNumber, returnStatus }
    });
  }
}

export default ReturnTrackerService;
