import { React, useState, useEffect, useCallback } from 'react';
import Loader from '../../core/loader/loader';
import Profile from '../../common/profile';
import ServiceRequest from '../../core/service/serviceRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import DataTransferService from '../../core/service/dataTransferService';
import Flex from '../../common/flex';
import { PaginationComponent } from '../../common/pagination';
import SearchInput from '../../common/search';
import { DropdownComponent } from '../../common/dropDown';
import { Table } from 'react-bootstrap';
import { numberFormatter } from '../../common/numberFormatter';

const VehicleBilling = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('CAR');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const [vehicleRequests, setReturnRequests] = useState([]);
  const selectOptions = [
    { id: 1, label: 'CAR', value: 'CAR' },
    { id: 2, label: 'TRUCK', value: 'TRUCK' }
  ];

  const handleChangeOptions = option => {
    setSortOption(option);
  };

  const vehicleData = useCallback(
    (vehicleType, page, size) => {
      setLoading(true);
      setErrorMessage('');
      const serviceRequest = new ServiceRequest();
      serviceRequest
        .fetchVehicleBilling(
          vehicleType,
          page,
          size,
          organisationName,
          branchName
        )
        .then(response => {
          if (response.data.responseCode === 200) {
            setLoading(false);
            setReturnRequests(response.data.responseBody.services);
            setTotalItems(response.data.responseBody.totalItems);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('vehicleData', error.response);
          setErrorMessage(error.response.data.responseMessage);
        });
    },
    [branchName, organisationName]
  );

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
    setPageIndex(0);
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  useEffect(() => {
    vehicleData(sortOption, pageIndex, pageSize);
  }, [sortOption, pageIndex, pageSize, vehicleData]);

  const searchVehicleBilling = useCallback(
    (vehicleNumber, page, size) => {
      setLoading(true);
      setErrorMessage('');
      const serviceRequest = new ServiceRequest();
      serviceRequest
        .searchBilling(vehicleNumber, page, size, organisationName, branchName)
        .then(response => {
          if (response.data.responseCode === 200) {
            setLoading(false);
            setReturnRequests(response.data.responseBody.services);
            setTotalItems(response.data.responseBody.totalItems);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('searchVehicleBilling', error.response);
          setErrorMessage(error.response.data.responseMessage);
        });
    },
    [branchName, organisationName]
  );

  useEffect(() => {
    if (searchTerm) {
      searchVehicleBilling(searchTerm, pageIndex, pageSize);
    } else {
      vehicleData(sortOption, pageIndex, pageSize);
    }
  }, [
    sortOption,
    pageIndex,
    pageSize,
    setSortOption,
    searchTerm,
    searchVehicleBilling,
    vehicleData
  ]);
  const formatNumber = number => {
    return number.toLocaleString();
  };

  return (
    <div className="vehiclebilling-container p-4">
      {loading && <Loader loading={loading} />}
      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="background-billing mt-5">
        <h1 className="title-box pt-3">Vehicle Data from Billing</h1>

        <div className="pt-3">
          <div className="d-flex align-items-center justify-content-between">
            {!searchTerm && (
              <DropdownComponent
                value={sortOption}
                selected={
                  <Flex className="d-flex align-items-center">
                    <span className="fs-12 fw-400 grayish-blue pe-2 vehicle-type">
                      Vehicle Type:
                    </span>
                    {sortOption}
                  </Flex>
                }
                options={selectOptions}
                onSelect={handleChangeOptions}
                toggleClassName="fs-14 fw-semibold sort-drop-down custom-arrow"
              />
            )}

            <SearchInput
              value={searchTerm}
              onChange={handleSearchChange}
              clearSearch={clearSearch}
              isFocused={isFocused}
              setIsFocused={setIsFocused}
              placeholder="Search"
              className="w-25"
            />
          </div>
          <div className="table-responsive pt-3">
            <Table className="table">
              <thead>
                <tr className="billing-col">
                  <th>Customer Name</th>
                  <th>Mobile Number</th>
                  <th>Vehicle Number</th>
                  <th>Vehicle Company</th>
                  <th>Vehicle Model</th>
                </tr>
              </thead>
              <tbody>
                {errorMessage ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <div className="text-center text-danger">
                        {errorMessage}
                      </div>
                    </td>
                  </tr>
                ) : (
                  vehicleRequests.map((request, index) => (
                    <tr key={index}>
                      <td>{request.customer}</td>
                      <td>{request.mobileNumber}</td>
                      <td>{request.vehicleNumber}</td>
                      <td>{request.vehicleCompany}</td>
                      <td>{request.vehicleModel}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
            <span>
              {totalItems > 0
                ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                    (pageIndex + 1) * pageSize,
                    totalItems
                  )} of ${formatNumber(totalItems)} entries`
                : 'No entries available'}
            </span>

            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
              isPrevDisabled={pageIndex === 0}
              pageIndex={pageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default VehicleBilling;
