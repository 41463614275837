import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const loaderStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(2px)'
};

const Loader = ({ loading }) => {
  return (
    loading && (
      <div
        style={loaderStyles}
        role="status"
        aria-live="polite"
        aria-hidden={!loading}
      >
        <ClipLoader color={'#5932EA'} loading={loading} size={50} />
      </div>
    )
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default Loader;
