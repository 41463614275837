import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { wheelerBillingValidations } from '../../validations/forms';
import DataTransferService from '../../core/service/dataTransferService';
import Profile from '../../common/profile';
import Flex from '../../common/flex';
import InvoiceService from '../../core/service/invoiceService';
import { toast } from 'react-toastify';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { useNavigate } from 'react-router-dom';
import Loader from '../../core/loader/loader';
import {
  InputField,
  SelectField,
  ServiceField
} from '../../validations/inputFields';
import { Back } from '../../common/back';
import { vehicleModel } from '../../common/commonApis';
import { toPascalCase } from '../../common/shared';

const WheelerBilling = () => {
  const initialValues = {
    services: {
      wheelAlignment: {
        selected: false,
        wheelAlignmentPrice: ''
      },
      wheelBalancing: {
        selected: false,
        wheelBalancingQuantity: '',
        wheelBalancingPrice: ''
      },
      weightBalancing: {
        selected: false,
        weightBalancingQuantity: '',
        weightBalancingPrice: ''
      },
      tyreChanging: {
        selected: false,
        tyreChangingQuantity: '',
        tyreChangingPrice: ''
      },
      tyreRotation: {
        selected: false,
        tyreRotationQuantity: '',
        tyreRotationPrice: ''
      },
      tyrePuncture: {
        selected: false,
        tyrePunctureQuantity: '',
        tyrePuncturePrice: ''
      },
      sims: {
        selected: false,
        simsQuantity: '',
        simsPrice: ''
      },
      nitrogen: {
        selected: false,
        nitrogenQuantity: '',
        nitrogenPrice: ''
      },
      others: { selected: false, othersQuantity: '', othersPrice: '' }
    },
    customerMobileNumber: '',
    customerName: '',
    driverMobileNumber: '',
    vehicleNumber: '',
    vehicleManufacturer: '',
    customVehicleManufacturer: '',
    vehicleModel: '',
    customVehicleModel: '',
    vehicleOdometerReading: '',
    vehicleAverageKmsPerDay: '',
    useGstInvoice: false,
    gstinNumber: '',
    businessName: '',
    createdOn: ''
  };

  const dataTransferService = new DataTransferService();
  const invoiceService = new InvoiceService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [responseVehicleModel, setResponseVehicleModel] = useState('');

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async values => {
    const selectedServices = [
      values.services.wheelAlignment.selected,
      values.services.wheelBalancing.selected,
      values.services.weightBalancing.selected,
      values.services.tyreChanging.selected,
      values.services.tyreRotation.selected,
      values.services.tyrePuncture.selected,
      values.services.sims.selected,
      values.services.nitrogen.selected,
      values.services.others.selected
    ];
    const isAnyServiceSelected = selectedServices.some(service => service);

    if (!isAnyServiceSelected) {
      toast.error('Please select at least one service.', {
        theme: 'colored'
      });
      return;
    }

    const formattedData = {
      customerDTO: {
        customerMobileNumber: values.customerMobileNumber,
        customerName: values.customerName
      },
      ...((values.businessName || values.gstinNumber) && {
        businessDetailsDTO: {
          businessName: values.businessName,
          gstinNumber: values.gstinNumber
        }
      }),
      vehicleDTO: {
        vehicleNumber: values.vehicleNumber,
        vehicleManufacturer: values.vehicleManufacturer.toUpperCase(),
        ...(values.vehicleManufacturer === 'Other' && {
          customVehicleManufacturer:
            values.customVehicleManufacturer.toUpperCase()
        }),
        vehicleModel: values.vehicleModel.toUpperCase(),
        ...(values.vehicleModel === 'Other' && {
          customVehicleModel: values.customVehicleModel.toUpperCase()
        }),
        vehicleOdometerReading: values.vehicleOdometerReading,
        vehicleAverageKmsPerDay: values.vehicleAverageKmsPerDay,
        driverMobileNumber: values.driverMobileNumber,
        vehicleType: 'CAR'
      },
      servicesDTO: {
        ...(values.services.wheelAlignment.selected && {
          wheelAlignment: values.services.wheelAlignment.selected,
          wheelAlignmentPrice:
            values.services.wheelAlignment.wheelAlignmentPrice
        }),
        ...(values.services.wheelBalancing.selected && {
          wheelBalancing: values.services.wheelBalancing.selected,
          wheelBalancingQuantity:
            values.services.wheelBalancing.wheelBalancingQuantity,
          wheelBalancingPrice:
            values.services.wheelBalancing.wheelBalancingPrice
        }),
        ...(values.services.weightBalancing.selected && {
          weightBalancing: values.services.weightBalancing.selected,
          weightBalancingQuantity:
            values.services.weightBalancing.weightBalancingQuantity,
          weightBalancingPrice:
            values.services.weightBalancing.weightBalancingPrice
        }),
        ...(values.services.tyreChanging.selected && {
          tyreChanging: values.services.tyreChanging.selected,
          tyreChangingQuantity:
            values.services.tyreChanging.tyreChangingQuantity,
          tyreChangingPrice: values.services.tyreChanging.tyreChangingPrice
        }),
        ...(values.services.tyreRotation.selected && {
          tyreRotation: values.services.tyreRotation.selected,
          tyreRotationQuantity:
            values.services.tyreRotation.tyreRotationQuantity,
          tyreRotationPrice: values.services.tyreRotation.tyreRotationPrice
        }),
        ...(values.services.tyrePuncture.selected && {
          tyrePuncture: values.services.tyrePuncture.selected,
          tyrePunctureQuantity:
            values.services.tyrePuncture.tyrePunctureQuantity,
          tyrePuncturePrice: values.services.tyrePuncture.tyrePuncturePrice
        }),
        ...(values.services.sims.selected && {
          sims: values.services.sims.selected,
          simsQuantity: values.services.sims.simsQuantity,
          simsPrice: values.services.sims.simsPrice
        }),
        ...(values.services.nitrogen.selected && {
          nitrogen: values.services.nitrogen.selected,
          nitrogenQuantity: values.services.nitrogen.nitrogenQuantity,
          nitrogenPrice: values.services.nitrogen.nitrogenPrice
        }),
        ...(values.services.others.selected && {
          others: values.services.others.selected,
          othersDescription: values.services.others.othersQuantity,
          othersPrice: values.services.others.othersPrice
        })
      }
    };

    submitForm(formattedData);
  };

  const submitForm = formattedData => {
    setLoading(true);
    invoiceService
      .generateCarInvoice(
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        formattedData
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          navigate(`/invoice/${response.data.responseBody}`);
          toast.success('Invoice Generated Successfully', {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('generate-invoice', error.response);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <div className="box-container p-4 d-flex justify-content-start flex-direction-column">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-4 fw-600">Bill New Service</p>
        </div>

        <hr className="mt-3" />

        <Formik
          initialValues={initialValues}
          validationSchema={wheelerBillingValidations}
          onSubmit={handleSubmit}
          validateOnChange={true}
        >
          {formik => (
            <Form>
              <div className="row pt-3 ">
                <div className="col">
                  <InputField
                    label="Customer Mobile Number"
                    name="customerMobileNumber"
                    type="text"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control w-75"
                  />
                </div>

                <div className="col">
                  <InputField
                    label="Customer Name"
                    name="customerName"
                    type="text"
                    placeholder="Enter Customer Name"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row pt-3 ">
                <div className="col">
                  <InputField
                    label="Vehicle Number"
                    name="vehicleNumber"
                    type="text"
                    placeholder="Enter Vehicle Number"
                    className="form-control w-75"
                  />
                </div>

                <div className=" col">
                  <SelectField
                    label="Vehicle Manufacturer"
                    name="vehicleManufacturer"
                    options={[
                      'Toyota',
                      'Honda',
                      'Ford',
                      'BMW',
                      'AUDI',
                      'Other'
                    ]}
                    className="form-control w-75"
                    onChange={e => {
                      const selectedManufacturer = e.target.value;
                      selectedManufacturer.toUpperCase();
                      formik.setFieldValue(
                        'vehicleManufacturer',
                        selectedManufacturer
                      );
                      vehicleModel(selectedManufacturer, {
                        setLoading,
                        setResponseVehicleModel
                      });
                    }}
                  />
                  {formik.values.vehicleManufacturer === 'Other' && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Vehicle Manufacturer"
                        name="customVehicleManufacturer"
                        type="text"
                        placeholder="Enter Vehicle Manufacturer"
                        className="form-control w-75"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col">
                  <SelectField
                    label="Vehicle Model"
                    name="vehicleModel"
                    options={
                      responseVehicleModel.length > 0
                        ? responseVehicleModel.map(model =>
                            toPascalCase(model.label)
                          )
                        : []
                    }
                    className="w-75"
                    disabled={
                      !formik.values.vehicleManufacturer ||
                      responseVehicleModel.length === 0 ||
                      loading
                    }
                  />

                  {formik.values.vehicleModel === 'Other' && (
                    <div className="mt-3">
                      <InputField
                        label="Specify Vehicle Model"
                        name="customVehicleModel"
                        type="text"
                        placeholder="Vehicle Model"
                        className="form-control w-75 "
                      />
                    </div>
                  )}
                </div>

                <div className="col">
                  <InputField
                    label="Vehicle Odometer Reading"
                    name="vehicleOdometerReading"
                    type="number"
                    placeholder="Enter Vehicle Odometer Reading"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Vehicle Average KM's Per Day"
                    name="vehicleAverageKmsPerDay"
                    type="number"
                    placeholder="Enter Vehicle Average KM's Per Day"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <label className="fs-14 fw-500" htmlFor="createdOn">
                    Created On
                  </label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control w-75"
                    value={currentDate}
                    disabled
                    id="createdOn"
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <InputField
                    label="Driver Mobile Number"
                    name="driverMobileNumber"
                    type="text"
                    placeholder="Enter Driver Mobile Number"
                    className="form-control w-75"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="mt-3 fs-14 fw-500 pb-1">
                    Select the services<span className="color-star">*</span>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2"
                      style={{ color: '#cfcfcf' }}
                    />
                  </p>
                  <p className="muted-grayish-blue fs-14 fw-400">
                    Click all the services performed
                  </p>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="wheelAlignment"
                      serviceTitle="Wheel Alignment"
                      hideQuantity={true}
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="wheelBalancing"
                      serviceTitle="Wheel Balancing"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="weightBalancing"
                      serviceTitle="Balancing Weights"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyreChanging"
                      serviceTitle="Tyre Changing"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyreRotation"
                      serviceTitle="Tyre Rotation"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyrePuncture"
                      serviceTitle="Tyre Puncture"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField serviceName="sims" serviceTitle="Sims" />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="nitrogen"
                      serviceTitle="Nitrogen"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField serviceName="others" serviceTitle="Other" />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col d-flex align-items-center">
                  <Field
                    type="checkbox"
                    name="useGstInvoice"
                    className="form-check-input me-2"
                    id="useGstInvoice"
                  />
                  <label
                    className="form-check-label mb-0 fs-14 fw-500"
                    htmlFor="useGstInvoice"
                  >
                    Use GST Invoice
                  </label>
                </div>
              </div>

              {formik.values.useGstInvoice && (
                <div className="row mt-3">
                  <div className="col">
                    <InputField
                      label="GSTIN Number"
                      name="gstinNumber"
                      type="text"
                      placeholder="Enter GSTIN Number"
                      className="form-control w-75"
                      id="gstinNumber"
                    />
                  </div>
                  <div className="col">
                    <InputField
                      label="Business Name"
                      name="businessName"
                      type="text"
                      placeholder="Enter Business Name"
                      className="form-control w-75"
                      id="businessName"
                    />
                  </div>
                </div>
              )}

              <div className="col mt-3">
                <button type="submit" className="button-indigo">
                  Generate Invoice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WheelerBilling;
