import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Flex from '../../common/flex';
import AlertAnimation from '../../common/alertAnimation';
import Loader from '../../core/loader/loader';
import DataTransferService from '../../core/service/dataTransferService';
import { ServiceRequest } from '../../core/service/serviceRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import UserService from '../../core/service/userService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const UpComingAlerts = ({ vehicleNumber }) => {
  const message_container = {
    backgroundColor: '#E5FFD8',
    borderRadius: '0.25rem',
    padding: '1rem',
    width: '24.5rem',
    radius: '0.625rem'
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [servicesData, setServicesData] = useState({});
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const userService = new UserService();
  const serviceRequest = useMemo(() => new ServiceRequest(), []);
  const [errorMessage, setErrorMessage] = useState('');

  const alertByVehicleNumber = useCallback(
    (branchName, organisationName, vehicleNumber) => {
      setLoadingPage(true);
      serviceRequest
        .alertByVehicleNumber(branchName, organisationName, vehicleNumber)
        .then(response => {
          if (response.data.responseCode === 200) {
            setLoadingPage(false);
            setServicesData(response.data.responseBody);
          }
        })
        .catch(error => {
          setLoadingPage(false);
          if (error.response.data.responseCode === 404) {
            setLoadingPage(false);
            setErrorMessage(error.response.data.responseMessage);
          }
          handleErrorResponse('fetchServices', error.response);
        });
    },
    [serviceRequest]
  );

  useEffect(() => {
    alertByVehicleNumber(branchName, organisationName, vehicleNumber);
  }, [branchName, organisationName, vehicleNumber, alertByVehicleNumber]);

  const sendRemindNow = () => {
    setLoadingPage(true);
    userService
      .sendRemindNow(
        servicesData.mobileNumber,
        servicesData.vehicleNumber,
        dataTransferService.getSelectedOrganization(),
        dataTransferService.getSelectedBranch()
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoadingPage(false);
          toast.success(
            `Reminder sent successfully to ${servicesData.customerName}`,
            {
              theme: 'colored'
            }
          );
        }
      })
      .catch(error => {
        setLoadingPage(false);
        handleErrorResponse('sendRemindNow', error.response);
      });
  };

  function handleSendReminder() {
    if (!servicesData.storeLink) {
      toast.error('Please add the store link first');
      return;
    }
    sendRemindNow();
  }

  return (
    <div className="p-3">
      {loadingPage && <Loader loading={loadingPage} />}

      {errorMessage ? (
        <div className="text-center text-danger">{errorMessage}</div>
      ) : (
        <>
          <Flex className="align-items-center justify-content-between">
            <h6>Customer Name :</h6>
            <h6 className="fw-light">{servicesData.customerName || 'N/A'}</h6>
          </Flex>
          <Flex className="align-items-center justify-content-between pt-3">
            <h6>Customer Mobile :</h6>
            <h6 className="fw-light">{servicesData.mobileNumber || 'N/A'}</h6>
          </Flex>
          <Flex className="align-items-center justify-content-between pt-3">
            <h6>Vehicle Number :</h6>
            <h6 className="fw-light">{servicesData.vehicleNumber || 'N/A'}</h6>
          </Flex>
          <Flex className="align-items-center justify-content-between pt-3">
            <h6>Vehicle Model :</h6>
            <h6 className="fw-light">{servicesData.vehicleModel || 'N/A'}</h6>
          </Flex>
          <Flex className="align-items-center justify-content-between pt-3">
            <h6>Vehicle Avg KM’s :</h6>
            <h6 className="fw-light">
              {servicesData.vehicleAverageKmsPerDay
                ? `${servicesData.vehicleAverageKmsPerDay} Kms / Day`
                : 'N/A'}
            </h6>
          </Flex>
          <Flex className="align-items-center justify-content-between pt-3">
            <h6>Vehicle Odometer Reading :</h6>
            <h6 className="fw-light">
              {servicesData.vehicleOdometerReading
                ? `${servicesData.vehicleOdometerReading} Kms`
                : 'N/A'}
            </h6>
          </Flex>
          <AlertAnimation
            lastServiceDate={
              servicesData.lastService
                ? servicesData.lastService.split('T')[0]
                : 'N/A'
            }
            upcomingAlertDate={servicesData.upcomingAlert || 'N/A'}
          />
          <Flex className="justify-content-start mt-5">
            <span className="fs-22 fw-600">Send Alert Now</span>
          </Flex>
          <hr className="color mt-3" />
          <span className="mt-2">Message Preview :</span>
          <div className="mt-4" style={message_container}>
            <h6>Vehicle Maintenance Due Reminder</h6>
            <h6 className="mt-4 lh-base">
              Hello {servicesData.customerName || 'Customer'},
            </h6>

            <p className="fs-14 lh-base">
              We want to remind you about the importance of keeping your vehicle{' '}
              {servicesData.vehicleNumber} in top condition. We currently have
              Innova Tyres available, ensuring your vehicle is safe and reliable
              for your journeys.
            </p>

            <p className="fs-14 mt-3 lh-base">
              Please consider scheduling your next service appointment to avoid
              any issues.
            </p>

            <h6 className="mt-4 lh-base">Best regards,</h6>
            <h6 className="lh-base">
              {dataTransferService.getSelectedOrganization()},
            </h6>
            <h6 className="lh-base">Visit us: {servicesData.storeLink}</h6>
            <h6 className="lh-base">Contact: {'9885733339'}</h6>
          </div>
          <button
            type="submit"
            className="p-2 button-styling w-25"
            onClick={handleSendReminder}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="me-3 rotate-up" />
            Send Reminder Now
          </button>
        </>
      )}
    </div>
  );
};

UpComingAlerts.propTypes = {
  vehicleNumber: PropTypes.string.isRequired
};

export default UpComingAlerts;
